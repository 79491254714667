<template>
  <b-sidebar
    id="sidebar-task-handler"
    @change="
      (val) => {
        $emit('update:todo-task-handler-sidebar', val);
      }
    "
    sidebar-class="sidebar-lg"
    @shown="openSidebar()"
    @hidden="clearTaskData()"
    :visible="isTaskHandlerSidebarActive"
    bg-variant="white"
    shadow
    width="700px"
    backdrop
    no-header
    right
  >
    <!-- no-close-on-backdrop -->
    <div class="overlay" v-if="isLoading">
      <div class="spinner"></div>
    </div>

    <!-- Header -->
    <div v-else>
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          {{ modalTaskData.id ? 'Update Task' : 'New Task' }}
        </h5>

        <div>
          <feather-icon class="ml-1 cursor-pointer" icon="XIcon" size="16" @click="closeSidebar(false)" />
        </div>
      </div>

      <validation-observer #default="{ handleSubmit }" ref="refTaskSidebar" style="position: relative;">
        <b-form @submit.prevent="handleSubmit(saveTask)">
          <div class="mt-25">
            <b-col class="mb-1 bg-light-secondary w-100" cols="12">
              <span style="font-size: 1.2rem; font-weight: 500;" v-if="modalTaskData.topTaskId != 0 && modalTaskData.topTaskId != null" class="text-dark p-25"> {{ modalTaskData.topTaskTitle }}</span>
            </b-col>

            <b-col cols="12" class="mb-1">
              <validation-provider name="Task" #default="{ errors }" rules="required">
                <div class="d-flex align-items-center">
                  <label class="mr-1 w-25">Task Name</label>
                  <div class="w-75">
                    <b-form-input v-model="modalTaskData.taskName" id="h-name" placeholder="Write a task name" />
                    <small class="text-danger" v-if="errors && errors[0]">{{ errors[0] }}</small>
                  </div>
                </div>
              </validation-provider>
            </b-col>

            <b-col cols="12" class="mb-1">
              <validation-provider name="Assignee User" #default="{ errors }">
                <div class="d-flex align-items-center">
                  <label class="mr-1 w-25">Assignee</label>
                  <v-select v-model="modalTaskData.assignedUser" :taggable="true" :options="users" label="fullName" class="w-75" :reduce="(val) => val.id" placeholder="No Assignee"> </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="mb-1">
              <validation-provider name="Additional Assignee User" #default="{ errors }">
                <div class="d-flex align-items-center">
                  <label class="mr-1 w-25">Additional Assignee</label>
                  <v-select v-model="modalTaskData.additionalAssigneeUser" :taggable="true" multiple :options="users" label="fullName" class="w-75" :reduce="(val) => val.id" placeholder="No Additional Assignee"> </v-select>
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>
            <b-col cols="12" class="mb-1">
              <div class="d-flex align-items-center">
                <label class="mr-1 w-25">Due date</label>

                <b-form-datepicker :id="'modal-due-date'" placeholder="Add due date" v-model="modalTaskData.dueDate" :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }" locale="en" label-help="" :start-weekday="1" :hide-header="true" class="w-75" />
              </div>
            </b-col>

            <b-col cols="12" class="mb-1">
              <div class="d-flex align-items-center">
                <label class="mr-1 w-25">Priority</label>
                <v-select v-model="modalTaskData.priority" label="title" class="w-75" :reduce="(val) => val.title" :options="taskFilters"></v-select>
              </div>
            </b-col>
            <b-col cols="12" class="mb-1">
              <validation-provider name="Name" #default="{ errors }">
                <div class="d-flex align-items-center">
                  <label class="mr-1 w-25">Description</label>
                  <b-form-textarea v-model="modalTaskData.description" id="h-name" class="w-75" placeholder="What is this task about?" rows="5" />
                  <small class="text-danger">{{ errors[0] }}</small>
                </div>
              </validation-provider>
            </b-col>

            <!-- SUB TASKSS  -->
            <b-col cols="12" class="mb-2">
              <small v-if="modalTaskData.topTaskId == 0 && modalTaskData.sub_tasks.length > 0">Subtasks</small>

              <div class="task-content add-cell" @click="handlesubTaskAdd()" v-if="modalTaskData.topTaskId == 0">
                <div class="w-5"></div>
                <div class="w-95 d-flex justify-content-start">
                  <div class="d-flex align-items-center">
                    <i class="fa-regular fa-plus mr-1"></i>
                    <span>Add new subtask...</span>
                  </div>
                </div>
              </div>

              <div v-for="(task, index) in modalTaskData.sub_tasks" :key="index">
                <div class="task-content">
                  <div class="todo-cell w-5 d-flex justify-content-center">
                    <div class="custom-checkbox">
                      <span class="checkbox-icon" :class="{ checked: task.isCompleted }" @click="updateModalTaskIsCompleted(task)"></span>
                    </div>
                  </div>

                  <div class="todo-cell w-55 hover-background" style="border-left:none; cursor: pointer; transition: background-color 0.2s;" @click="handleTaskClick(task)">
                    <div class="task-name">
                      <div class="w-100">{{ task.taskName }}</div>
                    </div>
                  </div>

                  <div class="todo-cell w-10 hover-background text-center">
                    <div class="w-100">
                      <span v-if="task.userTitle != 'Assign This Task'">
                        <b-avatar :text="getAvatarText(task.userTitle)" variant="light-primary" size="28" style="align-self: flex-start;"></b-avatar>
                      </span>

                      <span v-else class="text-info">
                        <b-avatar text="?" variant="light-info" size="28" style="align-self: flex-start;"></b-avatar>
                      </span>
                    </div>
                  </div>

                  <div class="todo-cell w-30 hover-background">
                    <b-form-datepicker
                      :id="'sub-due-date-' + task.id.toString()"
                      placeholder="Add due date"
                      v-model="task.dueDate"
                      :date-format-options="{ year: 'numeric', month: 'short', day: 'numeric', weekday: 'short' }"
                      @input="updateModalTaskDueDate(task.id, task.dueDate)"
                      locale="en"
                      label-help=""
                      :start-weekday="1"
                      :hide-header="true"
                      style="border:none; background-color: transparent;"
                    />
                  </div>
                </div>
              </div>
            </b-col>
          </div>

          <div class="d-flex justify-content-end mt-2 mb-5">
            <b-button variant="primary" class="mr-2" type="submit">
              {{ modalTaskData.id ? 'Update' : 'Save' }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </div>
  </b-sidebar>
</template>

<script>
import { BSidebar, BForm, BFormGroup, BFormInput, BAvatar, BButton, BFormInvalidFeedback, BTabs, BTab, VBTooltip, BBadge } from 'bootstrap-vue';
import vSelect from 'vue-select';
import flatPickr from 'vue-flatpickr-component';
import Ripple from 'vue-ripple-directive';
import { ValidationProvider, ValidationObserver } from 'vee-validate';
import { required, email, url } from '@validations';
import { quillEditor } from 'vue-quill-editor';
import store from '@/store';
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue';
import router from '@/router';
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue';
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue';
import VuePerfectScrollbar from 'vue-perfect-scrollbar';
import Multiselect from 'vue-multiselect';
import moment from 'moment';
import axiosIns from '@/libs/axios';
import { formatDate, getAvatarText } from '../helpers';

export default {
  components: {
    // BSV
    BButton,
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BAvatar,
    BFormInvalidFeedback,
    BTabs,
    BTab,
    BBadge,

    // 3rd party packages
    vSelect,
    flatPickr,
    quillEditor,
    VuePerfectScrollbar,
    AppTimeline,
    AppTimelineItem,
    Multiselect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
    'b-tooltip': VBTooltip,
  },
  model: {
    prop: 'isTaskHandlerSidebarActive',
    event: 'update:is-task-handler-sidebar-active',
  },
  props: {
    isTaskHandlerSidebarActive: {
      type: Boolean,
      required: true,
    },

    modalTaskData: {
      type: Object,
      required: false,
    },

    blankTempTaskData: {
      type: Object,
      required: false,
    },

    taskFilters: {
      type: Array,
      required: false,
    },
  },

  data() {
    const perfectScrollbarSettings = {
      maxScrollbarLength: 150,
    };

    return {
      required,
      email,
      url,
      visibleCommentsCount: 3,
      showCommentToolbar: false,

      users: [],

      perfectScrollbarSettings,

      observer: null,
      limit: 10,
      userData: JSON.parse(localStorage.getItem('userData')),
      dateNtim: null,
      meetingDateNtim: null,
      provider: 'Teams',

      isLoading: true,

      newEmail: null,
      emailList: [],
    };
  },

  created() {},

  computed: {},

  mounted() {},

  methods: {
    formatDate,
    getAvatarText,
    closeSidebar(val) {
      this.$emit('update:is-task-handler-sidebar-active', val);
    },

    openSidebar(val) {
      this.getUsers();
    },

    getUsers() {
      this.isLoading = true;
      store
        .dispatch('todo/fetchUsers', [])
        .then((res) => {
          this.users = res.data;
          this.isLoading = false;
        })
        .catch((error) => {
          this.isLoading = false;
          this.$swal({
            title: 'Error!',
            text: 'User list could not be loaded!',
            icon: 'error',
            customClass: {
              confirmButton: 'btn btn-primary',
            },
            buttonsStyling: false,
          });
        });
    },

    handleTaskClick(task) {
      this.$emit('handle-task-click', task);
    },

    handlesubTaskAdd() {
      const xxxy = JSON.parse(JSON.stringify(this.blankTempTaskData));
      xxxy.topTaskTitle = this.modalTaskData.taskName;
      xxxy.topTaskId = this.modalTaskData.id;
      console.log(xxxy);
      this.$emit('update:modal-task-data', xxxy);

      console.log(this.modalTaskData);
      // this.isTaskHandlerSidebarActive = true;

      // this.$emit('update:is-task-handler-sidebar-active', false);
    },

    updateModalTaskIsCompleted(task) {
      this.$emit('update-task-is-completed', {
        id: task.id,
        isCompleted: task.isCompleted,
      });
      task.isCompleted = !task.isCompleted;
    },

    updateModalTaskDueDate(taskId, dueDate) {
      this.$emit('update-task-due-date', taskId, dueDate);
    },

    clearTaskData() {
      this.$refs.refTaskSidebar.reset();
      this.isLoading = true;
      this.$emit('update:is-task-handler-sidebar-active', false);
    },

    getSectionColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.textColor : '#FFFFFF';
    },

    getSectionBgColor(val) {
      const section = this.taskFilters.find((section) => section.title === val);
      return section ? section.bgColor : '#000000';
    },

    saveTask() {
      this.isLoading = true;

      if (this.modalTaskData.id != null) {
        store
          .dispatch('todo/updateProjectTask', this.modalTaskData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Task was updated successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            this.isLoading = false;
            this.$emit('update:is-task-handler-sidebar-active', false);
            this.$emit('emit-projects-update');

          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      } else {
        store
          .dispatch('todo/saveProjectTask', this.modalTaskData)
          .then((response) => {
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Success',
                text: 'Task was added successfully! 👍',
                icon: 'CheckCircleIcon',
                variant: 'success',
              },
            });
            this.isLoading = false;
            this.$emit('update:is-task-handler-sidebar-active', false);
            this.$emit('emit-projects-update');

          
          
          })
          .catch((error) => {
            this.isLoading = false;
            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Error',
                text: '❌ Please try again or report an issue to support.',
                icon: 'XIcon',
                variant: 'danger',
              },
            });
            console.log(error);
          });
      }
    },
  },
  watch: {},
};
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
@import '@core/scss/vue/libs/vue-flatpicker.scss';
@import '@core/scss/vue/libs/quill.scss';
</style>

<style lang="scss" scoped>
@import '~@core/scss/base/bootstrap-extended/include';

.overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.8); /* Semi-transparent white background */
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 999; /* Ensure it's on top of other elements */
}

.spinner {
  border: 4px solid rgba(255, 255, 255, 0.3);
  border-top: 4px solid #007bff; /* Blue spinner color */
  border-radius: 50%;
  width: 40px;
  height: 40px;
  animation: spin 1s linear infinite;
}

.task-content {
  display: flex;
  width: 100%;
}

.todo-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  border: 1px solid #e5e5e5;
}

.custom-checkbox .checkbox-icon {
  width: 15px;
  height: 15px;
  border: 1px solid #ccc;
  background-color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  border-radius: 50px;
}

.custom-checkbox .checkbox-icon:not(.checked)::before {
  content: '\2713';
  font-size: 10px;
  color: #000;
}

.custom-checkbox .checkbox-icon.checked {
  background-color: #198754;
}

.custom-checkbox .checkbox-icon.checked::before {
  content: '\2713';
  font-size: 14px;
  color: #fff;
}

.w-95 {
  width: 95%;
}

.w-55 {
  width: 55%;
}

.w-30 {
  width: 30%;
}

.w-10 {
  width: 10%;
}
.w-5 {
  width: 5%;
}

.add-cell {
  display: flex;
  align-items: center;
  padding: 5px;
  height: 40px;
  background-color: #dddddd20;
}

.add-cell:hover {
  background-color: #dddddd40;
  cursor: pointer;
}

.hover-background:hover {
  background-color: #edeae9;
  cursor: pointer;
  position: relative;
}

.hover-background:hover::after {
  content: '\23F7';
  position: absolute;
  top: 50%;
  right: 5px;
  transform: translateY(-50%);
  color: #bbb;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
</style>
